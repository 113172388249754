import "./Footer.scss";

function FooterCol2() {
  return (
    <>
      <div className="footerCol2">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="company-detail">
                <ul>
                  <li>PT OMPAY TEKNOLOGI SOFTWAREINDO</li>
                  <li>
                    Gedung Ascom Jl. Matraman Raya No. 67 RT.005 RW. 004 Kel.
                    Palmeriam Kec. Matraman Jakarta Timur 13140
                  </li>
                  <li>
                    No telp :
                    <a
                      href="tel:+081292366337"
                      className="no-underline primary-color"
                    >
                      +08 129 236 6337
                    </a>
                  </li>
                  <li>
                    Email :{" "}
                    <a
                      href="ompayteknologisoftwareindo@gmail.com"
                      className="no-underline primary-color"
                    >
                      ompayteknologisoftwareindo@gmail.com
                    </a>
                  </li>
                </ul>
                <span className="copy-right">
                  Copyright © 2023 PT OMPAY TEKNOLOGI SOFTWAREINDO
                </span>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterCol2;
