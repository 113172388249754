import "./Home.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faDesktop,
  faMugSaucer,
  faPuzzlePiece,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
import { faDropbox } from "@fortawesome/free-brands-svg-icons";

// import product0101 from "../assets/images/04/01-01.jpg";

function HomeContent1() {
  return (
    <div className="home-content-1">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="box clearfix">
              <div className="icons">
                <FontAwesomeIcon icon={faPuzzlePiece} />
              </div>
              <div className="content">
                <h4>Software & Program</h4>
                <p>
                  Tingkatkan kinerja perusahaan dengan Software yang sesuai
                  dengan Business Process anda.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="box clearfix">
              <div className="icons">
                <FontAwesomeIcon icon={faDesktop} />
              </div>
              <div className="content">
                <h4>Web Design</h4>
                <p>
                  Bangun identitas bisnis dan usaha anda di dunia Internet
                  melalui Website.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="box clearfix">
              <div className="icons">
                <FontAwesomeIcon icon={faRocket} />
              </div>
              <div className="content">
                <h4>SEO SERVICE</h4>
                <p>
                  Search Engine Page Rank akan memberikan anda peluang lebih
                  besar dibanding kompetitor.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="box clearfix">
              <div className="icons">
                <FontAwesomeIcon icon={faMugSaucer} />
              </div>
              <div className="content">
                <h4>IT Audit</h4>
                <p>
                  Evaluasi sistem, kinerja, dan infrastruktur penunjang sistem
                  teknologi informasi
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="box clearfix">
              <div className="icons">
                <FontAwesomeIcon icon={faDropbox} />
              </div>
              <div className="content">
                <h4>Hardware & Networking</h4>
                <p>
                  Hardware dan Infrastruktur Jaringan yang baik akan memberikan
                  kenyamanan dalam bekerja.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="box clearfix">
              <div className="icons">
                <FontAwesomeIcon icon={faComments} />
              </div>
              <div className="content">
                <h4>CONSULTING</h4>
                <p>
                  Konsultasi kan kebutuhan IT anda pada kami dan ketahui layanan
                  lain yang kami berikan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeContent1;
