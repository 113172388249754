import "./Footer.scss";
import FooterCol1 from "./FooterCol1";
import FooterCol2 from "./FooterCol2";

function Footer() {
  return (
    <>
      <div className="footer">
          <FooterCol1></FooterCol1>
          <FooterCol2></FooterCol2>
      </div>
    </>
  );
}

export default Footer;
