import "./Home.scss";

import PTImg01 from "../assets/images/01.png";
import PTImg02 from "../assets/images/02.png";
import PTImg03 from "../assets/images/03.png";
import PTImg04 from "../assets/images/04.png";

function HomeContent2() {
  return (
    <div className="home-content-2">
      <div className="container">
        <h5>
          <span>PT OMPAY Tech | Konsultan IT | Jakarta | Indonesia</span>
        </h5>

        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="bebas_biaya_konsultasi"
            role="tabpanel"
            aria-labelledby="bebas_biaya_konsultasi"
            tabIndex={0}
          >
            <div className="imgRow">
              <img src={PTImg01} className="img-fluid" alt="PT Image 01" />
            </div>
            <div className="contentRow">
              <h2>Bebas Biaya Konsultasi</h2>
              <h3>Konsultasikan kebutuhan IT anda pada kami</h3>
              <p>
                <span>
                  <a href="#">
                    <span>Konsultan IT </span>
                  </a>
                </span>
                - Dalam hal pemecahan suatu masalah khususnya dibidang IT dan
                langkah yang harus diambil maka ada baiknya melakukan konsultasi
                terlebih dahulu agar tindakan yang diambil dapat tepat sasaran.
                terlebih apabila menyangkut masalah System dan Infrastruktur
                yang berkaitan dengan Teknologi Informasi (IT) yang membutuhkan
                Sumber Daya dan Infrastruktur yang besar dan melibatkan banyak
                aspek dalam jalannya pekerjaan.
              </p>
              <p>
                Oleh sebab itu kami memberikan layanan konsultasi dan memberikan
                solusi bagi permasalahan anda khususnya pada bidang Teknologi
                Informasi (IT). Silahkan
                <span>
                  <a href="#">
                    <span> Hubungi Kami </span>
                  </a>
                </span>
                dan konsultasikan masalah dan kebutuhan Teknologi Informasi yang
                anda atau perusahaan butuhkan.
              </p>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="dukungan_24_jam"
            role="tabpanel"
            aria-labelledby="dukungan_24_jam"
            tabIndex={1}
          >
            <div className="imgRow">
              <img src={PTImg02} className="img-fluid" alt="PT Image 02" />
            </div>
            <div className="contentRow">
              <h2>Dukungan 24 Jam</h2>
              <h3>
                Team Support Kami Siap Melayani Keluhan &amp; Kebutuhan Anda
              </h3>
              <p>
                - Hari Kerja (Senin s/d Sabtu Pukul 09.00 - 16.00 WIB) : Layanan
                Telepon, Email dan Whatsapp.
              </p>
              <p>
                - Hari Kerja (Senin s/d Sabtu Pukul 17.00 - 08.00 WIB) : Email,
                Whatsapp.
              </p>
              <p>
                - Hari Minggu / Libur Hari Besar : Email, Blackberry Messanger.
              </p>
              <p>
                - Kami juga melayani pekerjaan pada hari minggu, libur hari
                besar, atau pekerjaan diluar jam kerja dengan perjanjian.
              </p>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="full_customize"
            role="tabpanel"
            aria-labelledby="full_customize"
            tabIndex={2}
          >
            <div className="imgRow">
              <img src={PTImg03} className="img-fluid" alt="PT Image 03" />
            </div>
            <div className="contentRow">
              <h2>Full Customize</h2>
              <h3>System &amp; Software yang sesuai dengan Business Process</h3>
              <p>
                Sangat banyak System, Software, atau Aplikasi di Internet yang
                menawarkan produk dan pilihan yang menarik dan harga yang
                terjangkau. Namun apakah ragam produk tersebut sesuai dengan
                Business Process anda atau perusahaan anda? apakah anda atau
                perusahaan anda harus merubah Business Process dan menyesuaikan
                dengan alur System, Software, atau Aplikasi yang anda atau
                perusahaan anda miliki?
              </p>
              <p>
                Disini kami menjawab pertanyaan tersebut dengan membangun
                System, Software, atau Aplikasi yang mengikuti Business Process
                yang anda atau perusahaan anda terapkan sehingga lebih Powerfull
                dan User Friendly.
              </p>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="layanan_purna_jual"
            role="tabpanel"
            aria-labelledby="layanan_purna_jual"
            tabIndex={3}
          >
            <div className="imgRow">
              <img src={PTImg04} className="img-fluid" alt="PT Image 04" />
            </div>
            <div className="contentRow">
              <h2>Layanan Purna Jual</h2>
              <h3>Perawatan dan Pengembangan System dan Software</h3>
              <p>
                Kami memberikan gratis layanan Perawatan Sistem dan Software
                yang menggunakan layanan kami selama 1 (satu) bulan terhitung
                sejak penggunaan Sistem atau Software atau menurut perjanjian
                yang telah disepakati sebelumnya oleh kedua belah pihak.
              </p>
              <p>
                Selain itu kami juga memberikan layanan Perawatan System dan
                Software secara berkala sesuai dengan kebutuhan pengguna demi
                kenyamanan dan kelancaran fungsi dan kerja dari Sistem dan
                Software tersebut. silahkan
                <a href="#">
                  <span>
                    <span> Hubungi Kami</span>
                  </span>
                </a>
                &nbsp;untuk mengetahui info lengkap dari layanan tersebut.
              
                <h1>
                  <a href="#" className="lastTag">Konsultan IT</a>
                </h1>
              </p>
            </div>
          </div>
        </div>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#bebas_biaya_konsultasi"
              type="button"
              role="tab"
              aria-controls="bebas_biaya_konsultasi"
              aria-selected="true"
            >
              Bebas Biaya Konsultasi
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="dukungan_24_jam"
              data-bs-toggle="tab"
              data-bs-target="#dukungan_24_jam"
              type="button"
              role="tab"
              aria-controls="dukungan_24_jam"
              aria-selected="false"
            >
              Dukungan 24 Jam
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="full_customize"
              data-bs-toggle="tab"
              data-bs-target="#full_customize"
              type="button"
              role="tab"
              aria-controls="full_customize"
              aria-selected="false"
            >
              Full Customize
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="layanan_purna_jual"
              data-bs-toggle="tab"
              data-bs-target="#layanan_purna_jual"
              type="button"
              role="tab"
              aria-controls="layanan_purna_jual"
              aria-selected="false"
            >
              Layanan Purna Jual
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default HomeContent2;
