import "./Breadcrumb.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

function Breadcrumb() {
  return (
    <>
      <div className="breadcrumb">
        <div className="container">
          <div className="content">
            <h6>
              <FontAwesomeIcon icon={faLocationDot} />
              <span>Home</span>
            </h6>
          </div>
        </div>
      </div>
    </>
  );
}

export default Breadcrumb;
