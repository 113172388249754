import "./Home.scss";
import HomeBanner from "./HomeBanner";
import HomeContent1 from "./HomeContent1";
import HomeContent2 from "./HomeContent2";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";

function Home() {
  return (
    <div className="home">
      <HomeBanner></HomeBanner>
      <HomeContent1></HomeContent1>
      <HomeContent2></HomeContent2>
      <Breadcrumb></Breadcrumb>
    </div>
  );
}

export default Home;
