import "./Home.scss";

function HomeBanner() {
  return (
    <div className="home-banner">
      <div className="container">
        <div className="row content">
          <div className="col">
            <h1>PT OMPAY Tech IT Consultant</h1>
            <h3>We Serve With Today's Technology</h3>
          </div>
          <div className="col" />
        </div>
      </div>
    </div>
  );
}

export default HomeBanner;
