import "./Header.scss";
import PTlogo from "../assets/images/PTlogo.png";

interface HeaderProps {
  currentPage: string;
  onPageChange: (newPage: string) => void;
}

function Header(props: HeaderProps) {
  const handlePageChange = (newPage: string) => {
    console.log("Changing page to:", newPage);
    props.onPageChange(newPage);
  };

  console.log("Current Page:", props.currentPage);

  return (
    <div className="header">
      <div className="header-menu">
        <div className="container clearfix">
          <div className="header-left float-start">
            <div className="logo">
              <img src={PTlogo} className="img-fluid" alt="PT Logo" />
            </div>
          </div>
          <div className="header-right float-end">
            <nav className="main-nav">
              <ul>
                <li>
                  <a
                    href="#"
                    onClick={() => handlePageChange("home")}
                    className={props.currentPage === "home" ? "active" : ""}
                  >
                    Home
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
