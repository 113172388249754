import "./Footer.scss";

function FooterCol1() {
  return (
    <>
      <div className="footerCol1">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <h5>Global Sitemap</h5>

              <div className="custom">
                <ul>
                  <li>
                    <a href="#">
                      <span>About Us</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Our Services</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Products</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Clients</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>News &amp; Info</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Contact Us</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <h5>Programing & Website</h5>

              <div className="custom">
                <ul>
                  <li>
                    <a href="#">
                      <span>Desktop Programing</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Web Based Programing</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Web Design</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>SEO Service</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Digital Magazine</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Digital Advertising</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <h5>Hardware & Networking</h5>

              <div className="custom">
                <ul>
                  <li>
                    <a href="#">
                      <span>Penjualan &amp; Pengadaan</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Installasi Software</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Installasi Jaringan</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Perbaikan &amp; Perawatan</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Installasi CCTV</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Service Station Franchise</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <h5>Featured</h5>

              <div className="custom">
                <ul>
                  <li>
                    <a href="#">
                      <span>
                        Software Jembatan Timbang Pabrik CPO Kelapa Sawit
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Software Pabrik CPO Minyak Kelapa Sawit</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Software Perkebunan Sawit</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Software Plasma Sawit</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Software Jembatan Timbang Online</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterCol1;
